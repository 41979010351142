import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { FaHeadset } from "react-icons/fa";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import PrivateRoute from './routing/PrivateRoute'
import { Navbar, Footer, Sidebar } from './components';
import { Home, GetStarted, Profile, Classes, AddClass, UpdateClass, Students, AddAddons, Addons, UpdateAddon, Transportation, AddTransportation, UpdateTransport, Discounts, AllTransactions, TransactionDetails, TransactionPerClass, Flexpay, FlexpayDetails, FlexpayHistory, WithdrawBalance, WithdrawCredit, Compliance, BusinessCompliance, PaymentCompliance, Cashflow, CashflowPending, InvoiceDetails, ClassPayments, SignIn, SignUp, Welcome, Onboarding, Documents, Completed, Reset, Recover, Pie, Support, DirectorsIdentity, PaymentDetails, UpdateStudent, AddonPayments, PaymentPerAddon, Transfer, ExpenseRecord, NotFound, ClassAddons, ExpenseDetails } from './pages';
import './App.css';
import { useStateContext } from './contexts/ContextProvider';

const App = () => {
  const { user, school, setCurrentColor, setCurrentMode, currentMode, activeMenu, currentColor } = useStateContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, [setCurrentColor, setCurrentMode]);

  //console.log(user + " from context");
  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      <BrowserRouter>
        <Switch>
          {/* auth  */}
          <Route path="/signin" component={SignIn} />
          <Route path="/signup" component={SignUp} />
          <Route path="/welcome/school" component={Welcome} />
          <PrivateRoute path="/onboarding/kyc" component={Onboarding} />
          <PrivateRoute path="/onboarding/status" component={Completed} />
          <Route path="/auth/reset" component={Reset} />
          <Route path="/auth/recover" component={Recover} />
          {/* end switch  */}
          <div className="flex relative dark:bg-main-dark-bg">
            <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}>
              <TooltipComponent content="Support" position="Top">
                <Link to="/support">
                  <button type="button" style={{ background: currentColor, borderRadius: '50%' }}
                    className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray">
                    <FaHeadset />
                  </button>
                </Link>
              </TooltipComponent>
            </div>
            {activeMenu ? (
              <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white shadow">
                <Sidebar />
              </div>
            ) : (
                <div className="w-0 dark:bg-secondary-dark-bg">
                  <Sidebar />
                </div>
              )}
            <div className={ activeMenu ? 'dark:bg-main-dark-bg bg-[#F9F5F0] min-h-screen md:ml-72 w-full' : 'bg-[#F9F5F0] dark:bg-main-dark-bg w-full min-h-screen flex-2' }>
              <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
                <Navbar user={user} school={school} />
              </div>
              <div>
                {/* dashboard  */}
                <PrivateRoute path="/" exact component={Home} />
                <PrivateRoute path="/home" component={Home} />
                <PrivateRoute path="/onboarding/get-started" exact component={GetStarted} />
                <PrivateRoute path="/onboarding/get-started/documents" exact component={Documents} />
                <PrivateRoute path="/onboarding/get-started/directors-identity" exact component={DirectorsIdentity} />

                {/* pages  */}
                <PrivateRoute path="/profile" component={Profile} />
                <PrivateRoute path="/classes" component={Classes} />
                <PrivateRoute path="/add-class" component={AddClass} />
                <PrivateRoute path="/update-class/:id" component={UpdateClass} />
                <PrivateRoute path="/students/:classId" exact component={Students} />
                <PrivateRoute path="/update-student/:studentId" component={UpdateStudent} />
                <PrivateRoute path="/addons/:id" component={Addons} />
                <PrivateRoute path="/add-addons" component={AddAddons} />
                <PrivateRoute path="/update-addon/:id" component={UpdateAddon} />
                <PrivateRoute path="/transportation" component={Transportation} />
                <PrivateRoute path="/add-transportation" component={AddTransportation} />
                <PrivateRoute path="/update-transport/:id" component={UpdateTransport} />
                <PrivateRoute path="/class-payments" component={ClassPayments} />
                <PrivateRoute path="/all-payments" component={AllTransactions} />
                <PrivateRoute path="/transaction-details/:id" component={TransactionDetails} />
                <PrivateRoute path="/class-transaction/:id/:class" exact component={TransactionPerClass} />
                <PrivateRoute path="/class-transaction/payment/:id"  component={PaymentDetails} />
                <PrivateRoute path="/flexpay" exact component={Flexpay} />
                <PrivateRoute path="/flexpay/flexpay-details/:id" component={FlexpayDetails} />
                <PrivateRoute path="/flexpay-history" component={FlexpayHistory} />
                <PrivateRoute path="/class-addons" exact component={ClassAddons} />
                <PrivateRoute path="/addon-payments/:id/:classId" exact component={AddonPayments} />
                <PrivateRoute path="/addon-payments/students/:id" component={PaymentPerAddon} />
                <PrivateRoute path="/financing/cashflow" component={CashflowPending} />
                <PrivateRoute path="/financing/details" component={InvoiceDetails} />
                {/*<PrivateRoute path="/customers" component={Customers} />
                <PrivateRoute path="/parents" component={Parents} />
                <PrivateRoute path="/students" component={Students} />*/}
                <PrivateRoute path="/withdraw" component={WithdrawBalance} />
                <PrivateRoute path="/transfer" component={Transfer} />
                <PrivateRoute path="/expenses" component={ExpenseRecord} />
                <PrivateRoute path="/expense-details/:id" component={ExpenseDetails} />
                <PrivateRoute path="/financing/withdraw" component={WithdrawCredit} />
                <PrivateRoute path="/compliance/personal" component={Compliance} />
                <PrivateRoute path="/compliance/business" component={BusinessCompliance} />
                <PrivateRoute path="/compliance/payment" component={PaymentCompliance} />
                <PrivateRoute path="/support" component={Support} />
                <PrivateRoute path="/discounts" component={Discounts} />

                {/* charts  */}
                <PrivateRoute path="/pie" component={Pie} />
                </div>
              <Footer />
            </div>
          </div>
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
