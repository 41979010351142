import axios from "axios";
import Cookies from 'js-cookie';

const BASE_URL = "https://productionapi.acceede.com/" //Production AWS
//const BASE_URL = "https://acceedeapi.herokuapp.com" //Production
//const BASE_URL = "https://production-imh7.onrender.com"
//const BASE_URL = "https://staging-s55s.onrender.com" //staging old
//const BASE_URL = "https://stagingapi.acceede.com" //staging
//const BASE_URL = "https://dev-djnt.onrender.com" //development
//const BASE_URL = "https://acceede.herokuapp.com/api/"

//const tokenWithUser = JSON.parse(localStorage.getItem("tokenWithUser"));
const TOKEN = Cookies.get('accessToken');

//console.log(tokenWithUser);
//console.log(TOKEN);

export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    'X-Requested-With': 'XMLHttpRequest',
    "x-access-token": ` ${TOKEN}`,
  },
});

// Create our Currency formatter.
export const formatter = {
  format: (value) => {
    // If value is NaN, undefined, null, or falsy, return ₦0
    if (isNaN(value) || value === undefined || value === null || value === '') {
      return '₦0';
    }

    // Ensure the value is a number before formatting
    return new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
      minimumFractionDigits: 0,
    }).format(Number(value));
  },
};