import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FiFolderPlus } from 'react-icons/fi'
import { BiMoney } from "react-icons/bi";
import { FaHeadset } from "react-icons/fa";
import { useStateContext } from '../contexts/ContextProvider';
import { AddTermModal } from '../components';


const GetStarted = () => {
  const { school } = useStateContext();
  //console.log(currentColor);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  
  return (
    <>
    <section className="dark:bg-gray-900">
      <div className="py-8 px-8 mx-auto lg:py-16 mt-10 lg:mt-0">
        <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-8 md:p-12 mb-8">
            <span className="bg-blue-100 text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded-md dark:bg-gray-700 dark:text-blue-400 mb-2">
              Welcome, Emmanuel!
            </span>
            <h1 className="text-gray-900 dark:text-white text-3xl md:text-3xl font-extrabold mb-2">Setup your financial management dashbord</h1>
            <p className="text-lg font-normal text-gray-500 dark:text-gray-400 mb-6">
              To activate your business, set up your school session/term and kindly provide the required business documents. Ensure compliance by submitting the necessary registration documents. Next, set up your business withdrawal account and determine your preferred withdrawal frequency. If you have any questions or need assistance, our support staff is available to help.
            </p>
            <button onClick={toggleModal} className={`text-white bg-[#4543B5] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}>
              Setup Session and Term <i class="fad fa-arrow-right mt-1 ml-2"></i>
            </button>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 mb-2 m-2">
          <div className="w-full bg-white pb-10 px-4 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div className="flex flex-col items-center text-center pt-10 px-4">
              <button type="button" className="bg-indigo-100 text-indigo-700 text-2xl opacity-0.9 rounded p-4 hover:drop-shadow-xl">
                <FiFolderPlus />
              </button>
              <span className="text-sm text-gray-500 dark:text-gray-400 p-4">Submit Compliance documents to complete business registration</span>
            </div>
            {school?.kyc_progress < 8 ? (
              <Link to="/onboarding/get-started/documents">
                <button className="w-full text-white bg-[#4543B5] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                  Continue
                </button>
              </Link>
            ) : (
              <button className="w-full text-white bg-[#4543b5cf] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                Completed
              </button>
            )}
          </div>

          <div className="w-full bg-white pb-10 px-4 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div className="flex flex-col items-center text-center pt-10 px-4">
              <button type="button" className="bg-indigo-100 text-indigo-700 text-2xl opacity-0.9 rounded p-4 hover:drop-shadow-xl">
                <BiMoney />
              </button>
              <span className="text-sm text-gray-500 dark:text-gray-400 p-4">Setup your business withdrawal account and withdrawal frequency</span>
            </div>
            {school?.kyc_progress !== 8 ? (
              <button className="w-full text-white bg-[#4543b5cf] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                Pending
              </button>
            ) : (
              <Link to="/compliance/payment">
                <button className="w-full text-white bg-[#4543B5] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                  Continue
                </button>
              </Link>
            )}
          </div>

          <div className="w-full bg-white pb-10 px-4 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div className="flex flex-col items-center text-center pt-10 px-4">
              <button type="button" className="bg-indigo-100 text-indigo-700 text-2xl opacity-0.9 rounded p-4 hover:drop-shadow-xl">
                <FaHeadset />
              </button>
              <span className="text-sm text-gray-500 dark:text-gray-400 p-4">Find answers to your questions and get in touch with our support staff</span>
            </div>
            <Link to="/support">
              <button className="w-full text-white bg-[#4543B5] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                Get Support
              </button>
            </Link>
          </div>
        </div>
      </div>
      {isModalOpen && 
        <AddTermModal
        closeModal={toggleModal}
        schoolId={school?._id}
        school={school}
      />}
    </section>
    </>
  );
};

export default GetStarted;
